// frontend/src/layouts/FooterPage.js

import React from 'react';
import '../styles/HeaderFooter.css';

const FooterPage = () => {
    return (
      <footer className="footer">
        <p>&copy; 2024 University Management System. All rights reserved.</p>
      </footer>
    );
  };
  
  export default FooterPage;