// frontend/src/pages/Signup.js

import React, { useState, useEffect } from 'react';
import '../styles/MyLogin.css';
import logo from '../asset/images/Logo.png';
import { FaUser, FaKey, FaEye, FaEyeSlash, FaEnvelope, FaIdBadge, FaList } from 'react-icons/fa';

const Signup = () => {
    const [fullname, setFullname] = useState('');
    const [memberId, setMemberId] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [role, setRole] = useState('Data Entry');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [companyInfo, setCompanyInfo] = useState({ HeadName: '', Address: '', SloganText: '' });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSignup = async (e) => {
        e.preventDefault();

        // Reset messages
        setErrorMessage('');
        setSuccessMessage('');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/signup-user/signup`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Fullname: fullname,
                    MembIDNO: memberId,
                    UserName: username,
                    Password: password,
                    Role: role
                })
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('Signup successful!');
                // Clear form inputs
                setFullname('');
                setMemberId('');
                setUsername('');
                setPassword('');
                setConfirmPassword('');
                setRole('Data Entry'); // Reset the role to default
            } else {
                setErrorMessage(data.error || 'Signup failed');
            }
        } catch (error) {
            setErrorMessage('Something went wrong. Please try again.');
        }
    };

    // Fetch company info when the component loads
    useEffect(() => {
        const fetchCompanyInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/company-info/company-info`);
                const data = await response.json();
                if (response.ok) {
                    setCompanyInfo(data);
                } else {
                    console.error('Failed to fetch company info:', data.error);
                }
            } catch (error) {
                console.error('Error fetching company info:', error);
            }
        };

        fetchCompanyInfo();
    }, []);

    return (
        <div className="login-container">
            <div className="login-box">
                <img src={logo} alt="Company Logo" className="logo" />
                {/* Dynamically render company information */}
                <h1 className="company-name">{companyInfo.HeadName}</h1>
                <p className="company-address">{companyInfo.Address}</p>
                <p className="company-slogan">{companyInfo.SloganText}</p>
                <form onSubmit={handleSignup}>
                    {/* Fullname */}
                    <div className="input-container">
                        <FaIdBadge className="icon" />
                        <input
                            type="text"
                            className={`input ${fullname ? 'has-value' : ''}`}
                            value={fullname}
                            onChange={(e) => setFullname(e.target.value)}
                        />
                        <label className="placeholder">Fullname</label>
                    </div>

                    {/* Member ID */}
                    <div className="input-container">
                        <FaIdBadge className="icon" />
                        <input
                            type="text"
                            className={`input ${memberId ? 'has-value' : ''}`}
                            value={memberId}
                            onChange={(e) => setMemberId(e.target.value)}
                        />
                        <label className="placeholder">Member ID</label>
                    </div>

                    {/* Username */}
                    <div className="input-container">
                        <FaUser className="icon" />
                        <input
                            type="text"
                            className={`input ${username ? 'has-value' : ''}`}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <label className="placeholder">Username</label>
                    </div>

                    {/* Password */}
                    <div className="input-container">
                        <FaKey className="icon" />
                        <input
                            type={showPassword ? 'text' : 'password'}
                            className={`input ${password ? 'has-value' : ''}`}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label className="placeholder">Password</label>
                        <div className="eye-icon" onClick={togglePasswordVisibility}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </div>
                    </div>

                    {/* Confirm Password */}
                    <div className="input-container">
                        <FaKey className="icon" />
                        <input
                            type={showPassword ? 'text' : 'password'}
                            className={`input ${confirmPassword ? 'has-value' : ''}`}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <label className="placeholder">Confirm Password</label>
                    </div>

                    {/* Role */}
                    <div className="input-container">
                        <FaList className="icon" />
                        <select
                            className={`input ${role ? 'has-value' : ''}`}
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                        >
                            <option value="Super Admin">Super Admin</option>
                            <option value="Admin">Admin</option>
                            <option value="Data Entry">Data Entry</option>
                        </select>
                        <label className="placeholder">Role</label>
                    </div>

                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                    {successMessage && <p className="success-message">{successMessage}</p>}

                    <button type="submit" className="sign-in-button">Sign Up</button>
                    <p className="sign-in-link">Already have an account? <a href="/login">Sign In</a></p>
                </form>
            </div>
        </div>
    );
}

export default Signup;
