// frontend/src/components/SecondLine.js

import React from 'react';
import PropTypes from 'prop-types';
import CompLogo from '../asset/images/Logo.png';
import '../styles/HeaderFooter.css';

const SecondLine = ({ companyInfo, username, role }) => {
  return (
    <div className="second-line">
      <div className="header-left">
        <img src={CompLogo} alt="University Logo" className="logo-img" />
      </div>
      <div className="header-center">
        <h1>{companyInfo.HeadName}</h1>
        <p>{companyInfo.Address}</p>
        <p>{companyInfo.SloganText}</p>
      </div>
      <div className="header-right">
        {username && role && (
          <div>
            <p>User: {username}</p>
            <p>Role: {role}</p>
          </div>
        )}
      </div>
    </div>
  );
};

SecondLine.propTypes = {
  companyInfo: PropTypes.shape({
    HeadName: PropTypes.string.isRequired,
    Address: PropTypes.string.isRequired,
    SloganText: PropTypes.string.isRequired,
  }).isRequired,
  username: PropTypes.string,
  role: PropTypes.string,
};

export default SecondLine;

