// frontend/src/pages/TestPage.js

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '../context/ThemeContext';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import '../styles/InputForm.css';

const TestPage = () => {
    const { theme } = useTheme();
    const isDarkMode = theme === 'dark-theme';

    const [formData, setFormData] = useState({
        budgNat: '',
        expendCode: '',
        expendHead: '',
        budgetNo: '',
        budgRemarks: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [subledgers, setSubledgers] = useState([]);
    const [loadingSubledgers, setLoadingSubledgers] = useState(false);
    const [showSubledgerList, setShowSubledgerList] = useState(false); // Control when to show the list

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form Data Submitted:", formData);

        const formattedData = {
            BudgetNo: formData.budgetNo,
            ExpendCode: formData.expendCode,
            ExpendHead: formData.expendHead,
            BudgNat: formData.budgNat,
            BudgRemarks: formData.budgRemarks
        };

        setIsSubmitting(true);
        try {
            const token = localStorage.getItem('authToken');

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/budget-head/subledger`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(formattedData)
            });

            const data = await response.json();
            if (response.ok) {
                alert('Record saved successfully');
                setFormData({
                    budgNat: '',
                    expendCode: '',
                    expendHead: '',
                    budgetNo: '',
                    budgRemarks: ''
                });
            } else {
                alert(data.error || 'Failed to save the record');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const budgetTypes = [
        { value: 1, label: 'चालु खर्च' },
        { value: 2, label: 'पुँजीगत खर्च' },
    ];

    // Function to load the subledger data when the button is clicked
    const handleShowSubledgers = async () => {
        setLoadingSubledgers(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-acchead/subledgers`);
            const data = await response.json();
            setSubledgers(data);
            setShowSubledgerList(true);  // Show the grid after fetching data
        } catch (error) {
            console.error('Error fetching subledger data:', error);
        } finally {
            setLoadingSubledgers(false);
        }
    };

    const columns = [
        { field: 'ExpendCode', headerName: 'खर्च संकेत कोड', width: 150 },
        { field: 'ExpendHead', headerName: 'खर्च संकेत विवरण', width: 300 },
    ];

    return (
        <form className="form-container">
            <div className="main-container">
                <h2 className="full-width-header">खर्च शिर्षक व्यवस्थापन फाराम</h2>

                <TextField
                    select
                    label="बजेट प्रकारः"
                    name="budgNat"
                    value={formData.budgNat}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: 150, margin: 2 }}
                    className={isDarkMode ? 'input-dark-mode' : ''}
                >
                    {budgetTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    label="खर्च शिर्षकः"
                    type="text"
                    name="expendCode"
                    value={formData.expendCode}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: 150, margin: 2 }}
                    className={isDarkMode ? 'input-dark-mode' : ''}
                />
                <TextField
                    label="खर्च संकेत विवरणः"
                    type="text"
                    name="expendHead"
                    value={formData.expendHead}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: 333, margin: 2 }}
                    className={isDarkMode ? 'input-dark-mode' : ''}
                />
                <TextField
                    label="बजेट संकेत नम्वर"
                    type="text"
                    name="budgetNo"
                    value={formData.budgetNo}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: 333, margin: 2 }}
                    className={isDarkMode ? 'input-dark-mode' : ''}
                />
                <TextField
                    label="कैफियत"
                    type="text"
                    name="budgRemarks"
                    value={formData.budgRemarks}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: 333, margin: 2 }}
                    className={isDarkMode ? 'input-dark-mode' : ''}
                />
            </div>

            <div className="form-button-container">
                <h2 className="full-width-header">कार्यसूची</h2>
                <div className="command-button">
                    <button
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Saving...' : 'क्रियाकलाप थप गर्ने'}
                    </button>
                    {['क्रियाकलाप सच्याउने', 'क्रियाकलाप हटाउने', 'सुची देखाउने'].map((label, index) => (
                        <button
                            key={index}
                            onClick={label === 'सुची देखाउने' ? handleShowSubledgers : null}
                        >
                            {label}
                        </button>
                    ))}
                </div>
            </div>

            {/* Display DataGrid if subledgers are fetched */}
            {showSubledgerList && (
                <div className="second-container">
                    <h2 className="full-width-header">खर्च शिर्षकको सूची</h2>
                    <div className="third-container">
                        {loadingSubledgers ? (
                            <CircularProgress />
                        ) : (
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={subledgers.map((item, index) => ({ id: index, ...item }))}
                                    columns={columns}
                                    pageSize={5}
                                    disableSelectionOnClick
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </form>
    );
};

export default TestPage;
