// frontend/src/pages/ProfileStudent.js

import React, { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '../context/ThemeContext';
import '../styles/InputForm.css';

const province = [
  { value: 1, label: 'कोशी' },
  { value: 2, label: 'मधेश' },
  { value: 3, label: 'बागमति' },
  { value: 4, label: 'गण्डकी' },
  { value: 5, label: 'लुम्विनी' },
  { value: 6, label: 'कर्णाली' },
  { value: 7, label: 'सुदुरपश्चिम' },
];

const genders = [
  { value: 1, label: 'पुरुष' },
  { value: 2, label: 'महिला' },
  { value: 3, label: 'अन्य' },
];



const ProfileStudent = () => {
  const { theme } = useTheme(); // Get the current theme
  const isDarkMode = theme === 'dark-theme'; // Check if the theme is dark

  const [formData, setFormData] = useState({
    membidno: '',
    expendCode: '',
    expendHead: '',
    budgetNo: '',
    budgRemarks: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);

    // Map frontend field names to match backend expected field names
    const formattedData = {
      BudgetNo: formData.budgetNo,
      ExpendCode: formData.expendCode,
      ExpendHead: formData.expendHead,
      BudgNat: formData.budgNat,
      BudgRemarks: formData.budgRemarks
    };

    setIsSubmitting(true);
    try {
      // Get the token from local storage
      const token = localStorage.getItem('authToken');

      // Send a POST request to save form data in the database
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/subledger`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}` // Send token for authorization
        },
        body: JSON.stringify(formattedData) // Send formatted data to the API
      });

      const data = await response.json();
      if (response.ok) {
        alert('Record saved successfully');

        setFormData({membidno: '', memnepname: '', memengname: '', memdob: '', gender: '', memmobile: '', memphone: '', mememail: '', memProvince: '', memdistrict: '', mempalika: '', memwardno: '', memstreet: '', memfathname: '', memmothname: ''});
        
      } else {
        alert(data.error || 'Failed to save the record');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const budgetTypes = [
    { value: 1, label: 'चालु खर्च' },
    { value: 2, label: 'पुँजीगत खर्च' },
  ];

  return (
    <form className="form-container">
      <div className="main-container">
        <h2 className="full-width-header">व्यक्तिगत प्रोफाईल फाराम</h2>
        <TextField label="खाता नम्वरः" type="text" name="membidno" value={formData.membidno} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="नाम थर (नेपालीमा):" type="text" name="memnepname" value={formData.memnepname} onChange={handleChange} variant="outlined" sx={{ width: 333, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="Full Name" type="text" name="memengname" value={formData.memengname} onChange={handleChange} variant="outlined" sx={{ width: 333, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="जन्म मितिः" type="text" name="memdob" value={formData.memdob} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField select label="लिङ्गः" variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} >
          {genders.map((option) => (<MenuItem key={option.value} value={option.value}>  {option.label}  </MenuItem>))}
        </TextField>
        <TextField label="मोवाईल नम्वरः" type="text" name="memmobile" value={formData.memmobile} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="फोन नम्वरः" type="text" name="memphone" value={formData.memphone} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="E-mail:" type="text" name="mememail" value={formData.mememail} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField select label="प्रदेशः" name="memProvince" value={formData.memProvince} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''}>
          {province.map((option) => (<MenuItem key={option.value} value={option.value}> {option.label} </MenuItem>))}
        </TextField>
        <TextField label="जिल्लाः" type="text" name="memdistrict" value={formData.memdistrict} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="पालिकाः" type="text" name="mempalika" value={formData.mempalika} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="वडा नं.:" type="text" name="memwardno" value={formData.memwardno} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="टोलः" type="text" name="memstreet" value={formData.memstreet} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="बाबुको नामः" type="text" name="memfathname" value={formData.memfathname} onChange={handleChange} variant="outlined" sx={{ width: 333, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="आमाको नामः" type="text" name="memmothname" value={formData.memmothname} onChange={handleChange} variant="outlined" sx={{ width: 333, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
      </div>

      <div className="second-container">
        <h2 className="full-width-header">खाताहरुको सूची</h2>
        <div className="third-container">
          {/* यसमा सदस्यको नामावली सुची बोलाउने */}
        </div>
      </div>

      <div className="form-button-container">
        <h2 className="full-width-header">कार्यसूची</h2>
        <div className="command-button">
          <button onClick={handleSubmit} disabled={isSubmitting}> {isSubmitting ? 'Saving...' : 'खाता थप गर्ने'} </button>
          {['खाता सच्याउने', 'खाता हटाउने', 'सुची देखाउने'].map((label, index) => (
            <button key={index}>{label}</button>
          ))}
        </div>
      </div>
    </form>
  );
};

export default ProfileStudent;
