//frontend/src/pages/BudgetHead.js

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '../context/ThemeContext';
import SubLedgerList from '../components/SubLedgers'; // Import the subledger list
import '../styles/InputForm.css';

const BudgetHead = () => {
    const { theme } = useTheme();
    const isDarkMode = theme === 'dark-theme';

    // Initialize form data with subledger fields
    const [formData, setFormData] = useState({
        budgNat: '',
        expendCode: '',
        expendHead: '',
        budgetNo: '',
        budgRemarks: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // When user selects an item from the subledger list
    const handleSelect = (selectedItem) => {
        setFormData({
            ...formData,
            expendCode: selectedItem.value,  // Set the subledger code in the expendCode field
            expendHead: selectedItem.label   // Set the subledger title in the expendHead field
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form Data Submitted:", formData);

        // Map frontend field names to match backend expected field names
        const formattedData = {
            BudgetNo: formData.budgetNo,
            ExpendCode: formData.expendCode,
            ExpendHead: formData.expendHead,
            BudgNat: formData.budgNat,
            BudgRemarks: formData.budgRemarks
        };

        setIsSubmitting(true);
        try {
            // Get the token from local storage
            const token = localStorage.getItem('authToken');
            
            // Send a POST request to save form data in the database
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/budget-head/subledger`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}` // Send token for authorization
                },
                body: JSON.stringify(formattedData) // Send formatted data to the API
            });

            const data = await response.json();
            if (response.ok) {
                alert('Record saved successfully');
                // You can reset the form after successful submission if needed
                setFormData({
                    budgNat: '',
                    expendCode: '',
                    expendHead: '', 
                    budgetNo: '',
                    budgRemarks: ''
                });
            } else {
                alert(data.error || 'Failed to save the record');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const budgetTypes = [
        { value: 1, label: 'चालु खर्च' },
        { value: 2, label: 'पुँजीगत खर्च' },
    ];

    return (
        <form className="form-container">
            <div className="main-container">
                <h2 className="full-width-header">खर्च शिर्षक व्यवस्थापन फाराम</h2>

                {/* Budget Type Dropdown */}
                <TextField
                    select
                    label="बजेट प्रकारः"
                    name="budgNat"
                    value={formData.budgNat}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: 150, margin: 2 }}
                    className={isDarkMode ? 'input-dark-mode' : ''}
                >
                    {budgetTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                {/* Input Fields */}
                <TextField
                    label="खर्च शिर्षकः"
                    type="text"
                    name="expendCode"
                    value={formData.expendCode}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: 150, margin: 2 }}
                    className={isDarkMode ? 'input-dark-mode' : ''}
                />
                <TextField
                    label="खर्च संकेत विवरणः"
                    type="text"
                    name="expendHead"
                    value={formData.expendHead}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: 333, margin: 2 }}
                    className={isDarkMode ? 'input-dark-mode' : ''}
                />
                <TextField
                    label="बजेट संकेत नम्वर"
                    type="text"
                    name="budgetNo"
                    value={formData.budgetNo}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: 333, margin: 2 }}
                    className={isDarkMode ? 'input-dark-mode' : ''}
                />
                <TextField
                    label="कैफियत"
                    type="text"
                    name="budgRemarks"
                    value={formData.budgRemarks}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ width: 333, margin: 2 }}
                    className={isDarkMode ? 'input-dark-mode' : ''}
                />
            </div>

            <div className="second-container">
                <h2 className="full-width-header">खर्च शिर्षकको सूची</h2>
                <div className="third-container">
                    {/* Subledger List */}
                    <SubLedgerList onSelect={handleSelect} />
                </div>
            </div>

            <div className="form-button-container">
                <h2 className="full-width-header">कार्यसूची</h2>
                <div className="command-button">
                    <button
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Saving...' : 'क्रियाकलाप थप गर्ने'}
                    </button>
                    {['क्रियाकलाप सच्याउने', 'क्रियाकलाप हटाउने', 'सुची देखाउने'].map((label, index) => (
                        <button key={index}>{label}</button>
                    ))}
                </div>
            </div>
        </form>
    );
};

export default BudgetHead;
