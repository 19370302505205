// frontend/src/components/TopHeader.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '../components/RibbonButton';
import { useTheme } from '../context/ThemeContext';
import { useAuth } from '../context/authContext'; // Import useAuth

const TopHeader = () => {
  const { toggleTheme } = useTheme();
  const { logout } = useAuth(); // Get logout function from context
  const navigate = useNavigate(); // For navigation

  const handleLogOUT = () => {
    logout(); // Call logout function
    navigate('/login'); // Redirect to login page
    console.log("You are logged out.");
  };

  return (
    <div className="top-header">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-25px', width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton iconClass="fas fa-globe" caption="Language" />
          <IconButton iconClass="fas fa-envelope" caption="Message" />
          <IconButton iconClass="fas fa-sync-alt" caption="Updates" />
        </div>
        <h4>University Management System</h4>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton iconClass="fas fa-paint-brush" caption="Themes" onClick={toggleTheme} />
          <IconButton iconClass="fas fa-cog" caption="Setting" />
          <IconButton iconClass="fas fa-sign-out-alt" caption="Logout" onClick={handleLogOUT} />
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
