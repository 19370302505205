//frontend/src/layouts/Navbar.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/MainThemes.css';
import '../styles/NavBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserShield, faMoneyCheckAlt, faFileInvoice } from '@fortawesome/free-solid-svg-icons'; // Import the Home icon

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    console.log('Search query:', searchQuery);
    // Implement your search functionality here (e.g., redirect, API call)
  };

  return (
    <nav className="navbar">
      <ul>
        <li>
          <Link to="/home"> <FontAwesomeIcon icon={faHome} /> गृह पृष्ठ</Link>
          <ul>
            <li> <Link to="#"> प्रणाली सेटीङ्ग</Link>
              <ul>
                <li><Link to="/budgethead"> खर्च शिर्षक</Link></li>
                <li><Link to="/testpage">परिक्षण पेज</Link></li>
                <li><Link to="#">कर्मचारी खाता</Link></li>
                <li><a href="#">समितिहरु</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a href="#"> <FontAwesomeIcon icon={faUserShield} />  सामान्य प्रशासन</a>
          <ul>
            <li>
              <a href="#">खाताहरु</a>
              <ul>
                <li><Link to="/custprofile">ग्राहक खाता</Link></li>
                <li><Link to="/studprofile">विद्यार्थि खाता</Link></li>
                <li><Link to="/stafprofile">कर्मचारी खाता</Link></li>
                <li><a href="#">समितिहरु</a></li>
              </ul>
            </li>
            <li>
              <a href="#">शैक्षिक प्रशासन</a>
              <ul>
                <li><a href="#">विद्यार्थि परिचयपत्र</a></li>
                <li><a href="#">प्रवेश पत्र</a></li>
                <li><a href="#">कर्मचारी परिचयपत्र</a></li>
              </ul>
            </li>
            <li>
              <a href="#">कार्यालय प्रशासन</a>
              <ul>
                <li><a href="#">चिठीपत्र दर्ता/चलानी</a></li>
                <li><a href="#">समय तालिका</a></li>
                <li><a href="#">शैक्षिक क्यालेण्डर</a></li>
              </ul>
            </li>
            <li>
              <a href="#">समितिहरु</a>
              <ul>
                <li><a href="#">व्यवस्थापन बोर्ड</a></li>
                <li><a href="#">सञ्चालक समिति</a></li>
                <li><a href="#">लगानी बोर्ड</a></li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <a href="#"> <FontAwesomeIcon icon={faMoneyCheckAlt} /> आर्थिक प्रशासन</a>
          <ul>
            <li>
              <a href="#">बजेट</a>
              <ul>
                <li><a href="#">बजेट तर्जुमा</a></li>
                <li><Link to="/budgetentry">योजना प्रविष्टी</Link></li>
                <li><a href="#">रकमान्तर</a></li>
                <li><a href="#">प्रतिवेदन</a></li>
              </ul>
            </li>
            <li>
              <a href="#">विद्यार्थि</a>
              <ul>
                <li><a href="#">स्टेटमेन्ट</a></li>
                <li><a href="#">फि पोष्टीङ्ग</a></li>
                <li><a href="#">शुल्क प्राप्ति</a></li>
                <li><a href="#">रकम सहित नामावली</a></li>
              </ul>
            </li>
            <li>
              <a href="#">कर्मचारी</a>
              <ul>
                <li><a href="#">स्टेटमेन्ट</a></li>
                <li><a href="#">तलवी भरपाई</a></li>
                <li><a href="#">भुक्तानी</a></li>
                <li><a href="#">रकम सहित नामावली</a></li>
              </ul>
            </li>
            <li>
              <a href="#">भुक्तानी दिनुपर्ने</a>
              <ul>
                <li><a href="#">स्टेटमेन्ट</a></li>
                <li><a href="#">दिनुपर्ने कारोवार</a></li>
                <li><a href="#">चुक्ता गरेको कारोवार</a></li>
                <li><a href="#">रकम सहित नामावली</a></li>
              </ul>
            </li>
            <li>
              <a href="#">भुक्तानी पाउनुपर्ने</a>
              <ul>
                <li><a href="#">स्टेटमेन्ट</a></li>
                <li><a href="#">पाउनुपर्ने कारोवार</a></li>
                <li><a href="#">प्राप्त भएको कारोवार</a></li>
                <li><a href="#">रकम सहित नामावली</a></li>
              </ul>
            </li>
            <li>
              <a href="#">म.ले.प. प्रतिवेदन</a>
              <ul>
                <li><a href="#">बैंक नगदी किताव</a></li>
                <li><a href="#">गोश्वारा भौचर</a></li>
                <li><a href="#">सन्तुलन परिक्षण</a></li>
                <li><a href="#">वासलात</a></li>
                <li><a href="#">आर्थिक विवरण</a></li>
              </ul>
            </li>
          </ul>
        </li>


        <li>
          <a href="#"> <FontAwesomeIcon icon={faFileInvoice} /> खाता व्यवस्थापन</a>
          <ul>
            <li><a href="#">नयाँ कारोवार</a></li>
            <li><a href="#">नगदी रसिद</a></li>
            <li><a href="#">गोश्वारा भौचर</a></li>
          </ul>
        </li>

      </ul>
      {/* Search Input and Button */}
      <div className="navbar-search">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
    </nav>
  );
};

export default Navbar;
