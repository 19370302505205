// frontend/scr/components/RibbonButton.js

import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/RibbonButton.css';

const RibbonButton = ({ iconClass, caption, onClick }) => { // Accept onClick as a prop
  return (
    <button className="ribbon-button" onClick={onClick}> {/* Pass onClick to the button */}
      <i className={iconClass} style={{ marginRight: '5px' }}></i>
      {caption}
    </button>
  );
};

export default RibbonButton;
