// frontend/src/pages/ProfileEmployee.js

import React, { useContext } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '../context/ThemeContext'; // Use the useTheme hook
import '../styles/InputForm.css';

const genders = [
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
  { value: 3, label: 'Other' },
];

const textFields = [
  { label: 'IDNO', type: 'text', width: 150 },
  { label: 'Date of Birth', type: 'date', width: 150 },
  { label: 'Contact Number', type: 'text', width: 150 },
  { label: 'Mobile Number', type: 'text', width: 150 },
  { label: 'Fullname (Nepali)', type: 'text', width: 333 },
  { label: 'Fullname (English)', type: 'text', width: 333 },
  { label: 'Province', type: 'text', width: 150 },
  { label: 'District', type: 'text', width: 150 },
  { label: 'Municipality', type: 'text', width: 333 },
  { label: 'Ward', type: 'text', width: 150 },
  { label: 'Street', type: 'text', width: 150 },
  { label: 'Email', type: 'email', width: 333 },
  { label: "Father's Name (English)", type: 'text', width: 333 },
  { label: "Father's Name (Nepali)", type: 'text', width: 333 },
  { label: "Mother's Name", type: 'text', width: 333 },
];

const ProfileEmployee = () => {
  const { theme } = useTheme(); // Get the current theme
  const isDarkMode = theme === 'dark-theme'; // Check if the theme is dark

  return (
    <div className={`form-container ${theme}-theme`}>
      <div className="form-item">
        <h1>Employee Profile</h1>
      </div>
      <div className="form-item form-item-field">
        {textFields.map((field, index) => (
          <TextField
            key={index}
            label={field.label}
            type={field.type}
            variant="outlined"
            sx={{ width: field.width, margin: 2 }}
            className={isDarkMode ? 'input-dark-mode' : ''}
          />
        ))}
        <TextField
          select
          label="Gender"
          variant="outlined"
          sx={{ width: 150, margin: 2 }}
          className={isDarkMode ? 'input-dark-mode' : ''}
        >
          {genders.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="form-item form-item-buttons">
        {['Add New Profile', 'Update Profile', 'Delete Profile', 'Show Report'].map((label, index) => (
          <button key={index}>{label}</button>
        ))}
      </div>
    </div>
  );
};

export default ProfileEmployee;
