// frontend/src/components/HeaderPage.js

import React from 'react';
import '../styles/HeaderFooter.css';
import TopHeader from '../components/TopHeader';
import MidHeader from '../components/SecondLine';
import NavBar from './NavBar';

const HeaderPage = ({ companyInfo }) => {
  return (
    <header className="mainheader">
      <TopHeader />
      <MidHeader companyInfo={companyInfo} />
      <div className="third-line">
        <NavBar />
      </div>
    </header>
  );
};

export default HeaderPage;
