// frontend/src/pages/BudgetPosting.js

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '../context/ThemeContext';
import '../styles/InputForm.css';

const actvtcompo = [{ value: 1, label: 'गुणस्तरीय शिक्षा' }, { value: 2, label: 'शिक्षामा पहुँच' }, { value: 3, label: 'लैङ्गिक समावेशिता' }, { value: 4, label: 'गरिवि निवारण' }, { value: 5, label: 'पूर्वाधार विकास' }, { value: 6, label: 'दिगो विकास' }, { value: 7, label: 'प्राविधिक शिक्षा' }];
const fiscalyear = [{ value: 1, label: '२०८०/८१' }, { value: 2, label: '२०८१/८२' }, { value: 3, label: '२०८२/८३' }];
const unitnames = [{ value: 1, label: 'पटक' }, { value: 2, label: 'वटा' }, { value: 3, label: 'जना' }, { value: 2, label: 'हजार' }, { value: 3, label: 'लाख' }];

const BudgetPosting = () => {
  const { theme } = useTheme(); // Get the current theme
  const isDarkMode = theme === 'dark-theme'; // Check if the theme is dark

  const [formData, setFormData] = useState({ fiscalyear: '', actvtcompo: '', actvtcode: '', actvtname: '', unitnames: '', actvtqty: '', actvtrate: '', totalamount: '', estimateone: '', estimatetwo: '', lastyearbudget: '', lastyearexpense: '', qoneqty: '', qoneamt: '', qtwoqty: '', qtwoamt: '', qthreeqty: '', qthreeamt: '', qfourqty: '', qfouramt: '', actvtdetails: '', actvtremarks: '' });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);

    setIsSubmitting(true);
    try {
      // Get the token from local storage
      const token = localStorage.getItem('authToken');

      // Send a POST request to save form data in the database
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/save-program/saveprogram`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}` // Send token for authorization
        },
        body: JSON.stringify(formData) // Send form data to the API
      });

      const data = await response.json();
      if (response.ok) {
        alert('Record saved successfully');

        setFormData({ fiscalyear: '', actvtcompo: '', actvtcode: '', actvtname: '', unitnames: '', actvtqty: '', actvtrate: '', totalamount: '', estimateone: '', estimatetwo: '', lastyearbudget: '', lastyearexpense: '', qoneqty: '', qoneamt: '', qtwoqty: '', qtwoamt: '', qthreeqty: '', qthreeamt: '', qfourqty: '', qfouramt: '', actvtdetails: '', actvtremarks: '' });
 
      } else {
        alert(data.message || 'Failed to save the record');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="form-container">
      <div className="main-container">
        <h2 className="full-width-header">योजना/कार्यक्रम प्रविष्टी फाराम</h2>
        <TextField select label="आ.व." name="fiscalyear" value={formData.fiscalyear} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''}>
          {fiscalyear.map((option) => (<MenuItem key={option.value} value={option.value}> {option.label} </MenuItem>))}
        </TextField>
        <TextField select label="कम्पोनेन्टः" name="actvtcompo" value={formData.actvtcompo} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''}>
          {actvtcompo.map((option) => (<MenuItem key={option.value} value={option.value}> {option.label} </MenuItem>))}
        </TextField>
        <TextField label="क्रियाकलाप कोडः" type="text" name="actvtcode" value={formData.actvtcode} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="आयोजना/क्रियाकलापको विवरणः" type="text" name="actvtname" value={formData.actvtname} onChange={handleChange} variant="outlined" sx={{ width: 333, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        
        <TextField select label="इकाईः" name="unitnames" value={formData.unitnames} onChange={handleChange} variant="outlined" sx={{ width: 89, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''}>
          {unitnames.map((option) => (<MenuItem key={option.value} value={option.value}> {option.label} </MenuItem>))}
        </TextField>      
        <TextField label="परिमाणः" type="text" name="actvtqty" value={formData.actvtqty} onChange={handleChange} variant="outlined" sx={{ width: 89, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="दरः" type="text" name="actvtrate" value={formData.actvtrate} onChange={handleChange} variant="outlined" sx={{ width: 89, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />

        <TextField label="जम्मा रकमः" type="text" name="totalamount" value={formData.totalamount} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="आगामी पहिलो वर्षः" type="text" name="estimateone" value={formData.estimateone} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="आगामी दोश्रो वर्षः" type="text" name="estimatetwo" value={formData.estimatetwo} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="गतको विनियोजनः" type="text" name="lastyearbudget" value={formData.lastyearbudget} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="गतको खर्चः" type="text" name="lastyearexpense" value={formData.lastyearexpense} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />

        <TextField label="प्रथम चौ. परिमाणः" type="text" name="qoneqty" value={formData.qoneqty} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="प्रथम चौ. रकमः" type="text" name="qoneamt" value={formData.qoneamt} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="दोश्रो चौ. परिमाणः" type="text" name="qtwoqty" value={formData.qtwoqty} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="दोश्रो चौ. रकमः" type="text" name="qtwoamt" value={formData.qtwoamt} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="तेश्रो चौ. परिमाणः" type="text" name="qthreeqty" value={formData.qthreeqty} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="तेश्रो चौ. रकमः" type="text" name="qthreeamt" value={formData.qthreeamt} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="चौंथो चौ. परिमाणः" type="text" name="qfourqty" value={formData.qfourqty} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="चौंथो चौ. रकमः" type="text" name="qfouramt" value={formData.qfouramt} onChange={handleChange} variant="outlined" sx={{ width: 150, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="कार्यक्रम/क्रियाकलापको थप जानकारीः" type="text" name="actvtdetails" value={formData.actvtdetails} onChange={handleChange} variant="outlined" sx={{ width: 333, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
        <TextField label="कैफीयत (यदी केही भएमा):" type="text" name="actvtremarks" value={formData.actvtremarks} onChange={handleChange} variant="outlined" sx={{ width: 333, margin: 2 }} className={isDarkMode ? 'input-dark-mode' : ''} />
      </div>

      <div className="second-container">
        <h2 className="full-width-header">खाताहरुको सूची</h2>
        <div className="third-container">
          {/* यसमा सदस्यको नामावली सुची बोलाउने */}
        </div>
      </div>

      <div className="form-button-container">
        <h2 className="full-width-header">कार्यसूची</h2>
        <div className="command-button">
          <button onClick={handleSubmit} disabled={isSubmitting}> {isSubmitting ? 'Saving...' : 'कार्यक्रम थप गर्ने'} </button>
          {['कार्यक्रम सच्याउने', 'कार्यक्रम हटाउने', 'सुची देखाउने'].map((label, index) => (
            <button key={index}>{label}</button>
          ))}
        </div>
      </div>
    </form>
  );
};

export default BudgetPosting;
