// frontend/src/components/DashChart.js

import React, { useEffect, useRef } from 'react';
import { Chart, ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

// Register necessary Chart.js components
Chart.register(ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const DashChart = ({ type, title, data }) => {
  const chartRef = useRef(null); // Reference to the chart

  const chartData = {
    labels: Object.keys(data),
    datasets: [{
      data: Object.values(data),
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    }],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: type === 'column3d' ? { 
      x: { beginAtZero: true }, 
      y: { beginAtZero: true }
    } : {},
  };

  useEffect(() => {
    return () => {
      // Cleanup the chart instance on unmount
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  return (
    <div style={{ height: '300px', width: '300px' }}>
      {type === 'pie' && <Pie ref={chartRef} data={chartData} options={options} />}
      {type === 'column3d' && <Bar ref={chartRef} data={chartData} options={options} />}
    </div>
  );
};

export default DashChart;
