// frontend/src/pages/HomePage.js

import React, { useEffect, useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import DashChart from '../components/DashChart';
import '../styles/HomePage.css';
import '../styles/MainThemes.css';

const HomePage = () => {
  const { theme } = useTheme();
  const [data, setData] = useState(null);

  useEffect(() => {
    document.body.className = theme;

    const fetchData = async () => {
      try {
        // const response = await fetch('/api/dash-chart/dashchart');
        const response = await fetch('http://localhost:5000/api/dash-chart/dashchart');

        const result = await response.json();
        console.log('Fetched data:', result);  // Debugging log
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [theme]);

  if (!data) {
    return <div>Loading...</div>;
  }

  // Format box4 data from array to object
  const formattedBox4 = data.box4.reduce((acc, item) => {
    acc[item.Role] = item.Users;
    return acc;
  }, {});

  return (
    <div className="container">
      <div className="box">
        <DashChart type="pie" title="3 Years Budget" data={data.box1} />
      </div>
      <div className="box">
        <DashChart type="pie" title="Budget VS Expense" data={data.box2} />
      </div>
      <div className="box">
        <DashChart type="column3d" title="Sexwise Customers" data={data.box3} />
      </div>
      <div className="box">
        <DashChart type="pie" title="Rolewise Users" data={formattedBox4} />
      </div>
    </div>
  );
};

export default HomePage;
