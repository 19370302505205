// frontend/src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DashBoardPage from './pages/DashBoard';
import HomePage from './pages/HomePage';
import LoginPage from './pages/MyLogin';
import SignupPage from './pages/Signup';
import ProfileCustomer from './pages/ProfileCustomer';
import ProfileEmployee from './pages/ProfileEmployee';
import ProfileStudent from './pages/ProfileStudent';
import BudgetPosting from './pages/BudgetPosting';
import BudgetHead from './pages/BudgetHead';
import TestPage from './pages/TestPage';

import { ThemeProvider, useTheme } from './context/ThemeContext';
import { AuthProvider, useAuth } from './context/authContext';
import './styles/MainThemes.css';
import './styles/HeaderFooter.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const App = () => {
  const { theme } = useTheme();
  const { isAuthenticated, isAuthChecked } = useAuth(); // Get isAuthChecked

  if (!isAuthChecked) {
    // Show a loading spinner while checking authentication
    return <div>Loading...</div>;
  }

  return (
    <div className={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          {isAuthenticated ? (
            <>
              <Route path="/" element={<DashBoardPage />}>
                <Route path="/home" element={<HomePage />} />
                <Route path="/custprofile" element={<ProfileCustomer />} />
                <Route path="/studprofile" element={<ProfileStudent />} />
                <Route path="/stafprofile" element={<ProfileEmployee />} />
                <Route path="/budgetentry" element={<BudgetPosting />} />
                <Route path="/budgethead" element={<BudgetHead />} />
                <Route path="/testpage" element={<TestPage />} />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}
        </Routes>
      </Router>
    </div>
  );
};

const Root = () => (
  <ThemeProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </ThemeProvider>
);

export default Root;
