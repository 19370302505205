// frontend/src/pages/DashBoard.js

import React, { useState, useEffect } from 'react';
import HeaderPage from '../layouts/HeaderPage';
import FooterPage from '../layouts/FooterPage';
import { Outlet } from 'react-router-dom';

const DashBoard = () => {
  const [companyInfo, setCompanyInfo] = useState({ HeadName: '', Address: '', SloganText: '' });

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/company-info/company-info`);
        const data = await response.json();
        document.title = "Dashboard - Gandaki University MIS";
        if (response.ok) {
          setCompanyInfo(data);
        } else {
          console.error('Failed to fetch company info:', data.error);
        }
      } catch (error) {
        console.error('Error fetching company info:', error);
      }
    };

    fetchCompanyInfo();
  }, []);

  return (
    <>
      <HeaderPage companyInfo={companyInfo} />
      <div className="dashboard-content">
        <Outlet />
      </div>
      <FooterPage />
    </>
  );
};

export default DashBoard;
