//frontend/src/components/SubLedgers.js

import React, { useState, useEffect } from 'react';
import { useTheme } from '../context/ThemeContext'; 
import '../styles/InputForm.css';

const SubLedgerList = ({ onSelect }) => {
  const { theme } = useTheme(); // Get the current theme from context
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubLedgers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-acchead`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const transformedOptions = data.map(item => ({
          value: item.ExpendCode,
          label: item.ExpendHead
        }));

        setOptions(transformedOptions);
      } catch (error) {
        console.error('Error fetching subledger options:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubLedgers();
  }, []);

  const handleSelect = (option) => {
    setSelectedItem(option.value === selectedItem ? null : option.value);
    onSelect(option);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={`gsdlist-list ${theme}`}> {/* Apply theme as a class */}
      {options.map((option) => (
        <div
          key={option.value}
          onClick={() => handleSelect(option)}
          className={`gsdlist-item ${selectedItem === option.value ? 'selected' : ''}`} // Add selected class for active item
          style={{
            cursor: 'pointer',
            padding: '5px',
            margin: '2px 0',
            borderRadius: '3px',
          }}
        >
          {option.value} - {option.label}
        </div>
      ))}
    </div>
  );
};

export default SubLedgerList;
