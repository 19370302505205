// frontend/src/pages/MyLogin.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/MyLogin.css'; 
import logo from '../asset/images/Logo.png';
import { AuthProvider, useAuth } from '../context/authContext';
import { FaUser, FaKey, FaEye, FaEyeSlash } from 'react-icons/fa';

const MyLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [companyInfo, setCompanyInfo] = useState({ HeadName: '', Address: '', SloganText: '' });
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const { login } = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            // const response = await fetch('http://localhost:5000/api/login', {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login-verify/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ UserName: username, Password: password }),
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem('authToken', data.token);
                login(data.token);
                setUsername(username);
                navigate('/home');
            } else {
                alert(data.error || 'Login failed');
            }            
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    // Fetch company info when the component loads
    useEffect(() => {
        const fetchCompanyInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/company-info/company-info`);
                const data = await response.json();
                document.title = "Login - Gandaki University MIS";
                if (response.ok) {
                    setCompanyInfo(data);
                } else {
                    console.error('Failed to fetch company info:', data.error);
                }
            } catch (error) {
                console.error('Error fetching company info:', error);
            }
        };

        fetchCompanyInfo();
    }, []);

    return (
        <div className="login-container">
            <div className="login-box">
                <img src={logo} alt="Company Logo" className="logo" />
                {/* Dynamically render company information */}
                <h1 className="company-name">{companyInfo.HeadName}</h1>
                <p className="company-address">{companyInfo.Address}</p>
                <p className="company-slogan">{companyInfo.SloganText}</p>
                <div className="input-container">
                    <FaUser className="icon" />
                    <input
                        type="text"
                        className={`input ${username ? 'has-value' : ''}`}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <label className="placeholder">Username</label>
                </div>

                <div className="input-container">
                    <FaKey className="icon" />
                    <input
                        type={showPassword ? 'text' : 'password'}
                        className={`input ${password ? 'has-value' : ''}`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <label className="placeholder">Password</label>
                    <div className="eye-icon" onClick={togglePasswordVisibility}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                </div>
                <button type="submit" class="sign-in-button" onClick={handleLogin}>Sign In</button>
                <p class="sign-up-link">Don't have an account? <a href="/signup">Sign Up</a></p>
            </div>
        </div>
    );
}

export default MyLogin;
